import React, { useCallback, useEffect, useRef, useState } from "react";
import { Venue } from "../../types/venue";
import { TextField, Typography, CircularProgress, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { SearchOutlined, Close } from "@mui/icons-material";
import VenueList from "./VenueList";
import BenefitsDropdown from "./BenefitsDropdown";
import useDebounce from "../../utils/useDebounce";
import { getVenues } from "../../utils/api/api";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { trackEvent } from "../../services/analytics/analytics";

const useStyles = makeStyles()((theme) => ({
  searchBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: theme.palette.background.default,
  },
  loadingContainer: {
    display: "relative",
  },
  loadingIndicator: {
    position: "absolute",
    top: "64vh",
    [theme.breakpoints.up("md")]: {
      top: "54vh",
    },
    left: "50%",
  },
  searchBarIcon: {
    marginRight: theme.spacing(4),
  },
  clearFieldIcon: {
    cursor: "pointer",
  },
  venueLinkText: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginBottom: theme.spacing(1),
  },
}));

type VenueSearchProps = {
  navigateToPaymentSelect: (venue: Venue) => void;
};
const LIMIT = 20;

export default function VenueSearch(props: Readonly<VenueSearchProps>) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  const { navigateToPaymentSelect } = props;

  const [venues, setVenues] = useState<Venue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [query, setQuery] = useState<string>("");
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);
  const [venueFetched, setVenueFetched] = useState<boolean>(false);
  const [noMoreSearchResults, setNoMoreSearchResults] =
    useState<boolean>(false);
  const startingAfter = useRef<string>("");

  const inputCleared = useRef<boolean>(false);
  const benefitsStr = selectedBenefits.filter((b) => b !== "all").join(",");

  const debouncedQuery = useDebounce(query, 500);
  const debouncedBenefits = useDebounce(benefitsStr, 500);

  const fetchVenueData = useCallback(
    async (useStartingAfter?: boolean) => {
      getVenues(
        debouncedQuery,
        debouncedBenefits,
        LIMIT,
        useStartingAfter ? startingAfter.current : ""
      )
        .then((response) => {
          const venues = response.data;
          setVenues((prevVenues) =>
            useStartingAfter ? [...prevVenues, ...venues] : venues
          );

          const venueIds = venues.map((venue: Venue) => venue.id);
          trackEvent({
            name: "search",
            params: {
              search_term: venueIds,
            },
          });

          startingAfter.current =
            venues.length > 0 ? venues[venues.length - 1].id : "";
          setNoMoreSearchResults(venues.length < LIMIT);
        })
        .catch(() => {
          setError("VenueSearch.error.fetch_failed");
        });
    },
    [debouncedQuery, debouncedBenefits]
  );

  useEffect(() => {
    if (debouncedQuery || debouncedBenefits) {
      const fetch = async () => {
        setLoading(true);
        setError(null);
        await fetchVenueData();
      };

      fetch();
    }
  }, [debouncedQuery, debouncedBenefits, fetchVenueData]);

  useEffect(() => {
    if (venueFetched) {
      return;
    }
    if ((debouncedQuery || debouncedBenefits) && !inputCleared.current) {
      setVenueFetched(true);
    }

    inputCleared.current = false;
  }, [debouncedQuery, debouncedBenefits, venueFetched]);

  useEffect(() => {
    if (venues || error) {
      setLoading(false);
    }
  }, [venues, error]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const benefitType = searchParams.get("benefitType");

    if (benefitType) {
      setSelectedBenefits(benefitType.split(","));
    }
  }, [location.search]);

  const handleShowResults = (benefits: string[]) => {
    setSelectedBenefits(benefits);
    const benefitQuery = benefits.join(",");
    if (benefitQuery) {
      navigate(`?benefitType=${benefitQuery}`);
    } else {
      navigate("");
    }
  };

  const handleLoadMore = async () => {
    if (startingAfter) {
      await fetchVenueData(true);
    }
  };

  const handleClickClearField = () => {
    setQuery("");
    setVenues([]);
    setVenueFetched(false);
    setError(null);
    startingAfter.current = "";
    inputCleared.current = true;
    inputRef.current?.focus();
  };

  return (
    <Grid>
      <Box mx={{ sm: 6, md: 10 }}>
        <Typography variant="h1" gutterBottom>
          <FormattedMessage id="VenueSearch.header" />
        </Typography>
        <Box mt={6} />
        <Typography variant="h3" gutterBottom>
          <FormattedMessage id="VenueSearch.subtitle" />
        </Typography>
        <Box mt={6} />
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage id="VenueSearch.subtitle1" />
        </Typography>
        <Box mt={6} />
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage id="VenueSearch.subtitle2" />
        </Typography>
        <Box mt={6} />
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage
            id="VenueSearch.subtitle3"
            values={{
              bold: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
        <Box mt={6} />
        <Typography variant="subtitle1" gutterBottom>
          <a
            className={classes.venueLinkText}
            href={intl.formatMessage({
              id: "VenueSearch.howToMakePaymentLink",
            })}
          >
            <FormattedMessage id="VenueSearch.howToMakePayment" />
          </a>
        </Typography>
        <Box mt={6} />

        <Box
          className={classes.searchBar}
          component="form"
          onSubmit={(event) => {
            event.preventDefault();
            if (inputRef.current) {
              inputRef.current.blur();
            }
          }}
        >
          <Typography variant="h6" gutterBottom color="textSecondary">
            {intl.formatMessage({ id: "VenueSearch.header" })}
          </Typography>
          <Box mt={2} />
          <TextField
            variant="outlined"
            placeholder={intl.formatMessage({
              id: "VenueSearch.inputPlaceholder",
            })}
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              className: "searchOutlinedInput",
              startAdornment: (
                <SearchOutlined className={classes.searchBarIcon} />
              ),
              endAdornment: query && (
                <Close
                  color="action"
                  className={classes.clearFieldIcon}
                  onClick={handleClickClearField}
                />
              ),
              inputRef: inputRef,
            }}
          />
        </Box>
        <Box mt={8} />
        <Box className={classes.searchBar}>
          <BenefitsDropdown
            handleShowResults={handleShowResults}
            selectedBenefits={selectedBenefits}
          />
          <Box mt={2} />
        </Box>
        <Box mt={8} />
      </Box>

      {loading && (
        <Box className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIndicator} size="26px" />
        </Box>
      )}
      {!loading && error && (
        <Typography color="error" variant="h3">
          <FormattedMessage id={error} />
        </Typography>
      )}
      <VenueList
        loading={loading}
        venues={venues}
        query={query}
        venueFetched={venueFetched}
        navigateToPaymentSelect={navigateToPaymentSelect}
        handleLoadMore={handleLoadMore}
        noMoreSearchResults={noMoreSearchResults}
      />
    </Grid>
  );
}
