import React, { useEffect, useState, useMemo } from "react";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage } from "react-intl";
import { StripeCardElement } from "@stripe/stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Title,
  Button,
  ButtonText,
  AccountWrapper,
  LoadingFrame,
  LoadingInnerFrame,
  NoUsableAccountText,
  NavigationInstruction,
} from "../../StyledComponents";
import StripeSection from "./StripeSection";
import Frame from "./Frame";
import formatCurrency from "../../../utils/formatCurrency";
import { IAccount } from "../../Pay";
import { InfoType } from "../../withAppAuth";
import { getChargeAmounts, shouldDisableAccount } from "../../../utils/stripe";

interface IProps {
  info: InfoType;
  accounts: IAccount[];
  isLoading: boolean;
  handleSubmit: (
    account: IAccount,
    saldoChargeAmount: number,
    PI: any,
    card: StripeCardElement | null
  ) => any;
  clearCountDown: () => any;
  locale: "fi" | "en";
}

const AccountSection: React.FC<IProps> = ({
  clearCountDown,
  handleSubmit,
  info,
  accounts,
  locale,
  isLoading,
}) => {
  const [selectedAccount, setSelectedAccount] = useState<IAccount>(
    {} as IAccount
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [stripeFormError, setStripeFormError] = useState<boolean>(false);
  const [card, setCard] = useState<StripeCardElement | null>(null);
  const [showNavInstruction, setShowNavInstruction] = useState<boolean>(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleChooseAccount = (account: IAccount) => {
    setSelectedAccount(account);
  };

  const submitPayment = async () => {
    setShowNavInstruction(true);

    if (!stripe || !elements) {
      return;
    }

    if (!isSubmitting) {
      setIsSubmitting(!isSubmitting);
    }

    if (card) {
      // TODO: find alternative solution to prevent user from submitting empty stripe form
      // calling createToken first to trigger stripe form validation
      const result = await stripe.createToken(card);
      if (result.error) {
        setIsSubmitting(false);
        return false;
      }
    }

    const PI = {} as any;
    if (info.stripe_account && stripeChargeAmount) {
      PI.account = info.stripe_account;
      PI.amount = stripeChargeAmount;
    }

    clearCountDown();
    handleSubmit(selectedAccount, saldoChargeAmount, PI, card);
  };

  const isOwnWalletAccount = (a: IAccount) => !a.employer;

  const usableAccounts = accounts.filter(
    (a) => includes(a.benefits, info.benefit) && !isOwnWalletAccount(a)
  );
  const hasNoUsableAccounts = !usableAccounts.length;

  const renderAccounts = () => {
    if (isLoading) {
      return (
        <LoadingFrame>
          <LoadingInnerFrame />
        </LoadingFrame>
      );
    }

    if (hasNoUsableAccounts) {
      return (
        <NoUsableAccountText>
          <FormattedMessage id="AccountSection.noUsableAccount" />
        </NoUsableAccountText>
      );
    } else {
      return usableAccounts.map((a) => {
        return (
          <Frame
            key={a.id}
            disabled={shouldDisableAccount(info, a)}
            checked={a.id === selectedAccount.id}
            handleClick={() => handleChooseAccount(a)}
            account={a}
            targetBenefitType={info.benefit}
          />
        );
      });
    }
  };

  const { stripeChargeAmount, saldoChargeAmount, hasMinimumStripeCharge } =
    useMemo(
      () => getChargeAmounts(info, selectedAccount),
      [info, selectedAccount]
    );

  const shouldStripeFormShow =
    info.stripe_account && !isEmpty(selectedAccount) && !!stripeChargeAmount;

  useEffect(() => {
    if (shouldStripeFormShow) {
      if (elements && stripe) {
        const elementsWithLocale = stripe.elements({
          locale,
        });

        setCard(elementsWithLocale.create("card"));
      }
    }
  }, [locale, shouldStripeFormShow, elements, stripe]);

  const isButtonDisabled =
    isEmpty(selectedAccount.id) || stripeFormError || isSubmitting;
  const shouldButtonHaveColor = !isButtonDisabled || isSubmitting;

  return (
    <AccountWrapper>
      <Title>
        {selectedAccount.id ? (
          <FormattedMessage
            id="AccountSection.title.payAmountWithSmartum"
            values={{
              price: formatCurrency(saldoChargeAmount),
            }}
          />
        ) : (
          <FormattedMessage
            id="AccountSection.title.general"
            defaultMessage="Maksa Smartumilla"
          />
        )}
      </Title>
      {renderAccounts()}
      {shouldStripeFormShow && (
        <StripeSection
          setStripeFormError={setStripeFormError}
          card={card}
          stripeChargeAmount={stripeChargeAmount}
          hasMinimumStripeCharge={hasMinimumStripeCharge}
        />
      )}
      <Button
        disabled={isButtonDisabled}
        hasColor={shouldButtonHaveColor}
        onClick={submitPayment}
      >
        <ButtonText>
          {isSubmitting ? (
            <FormattedMessage
              id="AccountSection.buttonRunningText"
              defaultMessage="Suoritetaan..."
            />
          ) : (
            <FormattedMessage
              id="AccountSection.buttonDefaultText"
              defaultMessage="Maksa"
              values={{
                price: `${formatCurrency(saldoChargeAmount + stripeChargeAmount)}€`,
              }}
            />
          )}
        </ButtonText>
      </Button>

      {showNavInstruction && (
        <NavigationInstruction>
          <FormattedMessage id="AppContent.navigationInstruction" />
        </NavigationInstruction>
      )}
    </AccountWrapper>
  );
};

export default AccountSection;
