import React from "react";
import isEmpty from "lodash/isEmpty";
import { Stripe, StripeElements, StripeCardElement } from "@stripe/stripe-js";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { AppContent } from "../StyledComponents";
import VenueSection from "./VenueSection";
import AccountSection from "./AccountSection";
import Modal from "./Modal";
import Countdown from "./Countdown";
import getPaymentErrorId from "../../utils/error/getPaymentErrorId";
import { makePayment, createPaymentIntent } from "../../utils/api";
import appendQueryString from "../../utils/appendQueryString";
import { IAccount } from "../Pay";
import { InfoType } from "../withAppAuth";
import { RECEIPT_SUCCESS_URL } from "../../constants";

interface InjectedAppContentProps {
  accessToken: string | null | undefined;
  info: InfoType;
  venue: any;
  accounts: IAccount[];
  isLoading: boolean;
  locale: "fi" | "en";
}
interface AppContentProps extends InjectedAppContentProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
}

interface AppContentState {
  hasResult: boolean;
  error: {
    hasError: boolean;
    errorId: string;
  };
}

export class AppContentComponent extends React.Component<
  AppContentProps,
  AppContentState
> {
  state = {
    hasResult: false,
    error: {
      hasError: false,
      errorId: "",
    },
  };
  handleSubmit = async (
    account: IAccount,
    saldoChargeAmount: number,
    PI: any,
    card: StripeCardElement | null
  ) => {
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    try {
      let paymentIntentId = "";
      if (!isEmpty(PI) && card) {
        const { clientSecret } = await createPaymentIntent(
          PI.account,
          PI.amount,
          this.props.accessToken
        );

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card,
          },
        });
        if (result.error) throw result.error;
        if (result.paymentIntent) {
          if (result.paymentIntent.status !== "requires_capture") {
            throw new Error("Something went wrong!");
          } else {
            paymentIntentId = result.paymentIntent.id;
          }
        }
      }

      const { res, jwt, receiptToken } = await makePayment(
        account,
        this.props.info,
        this.props.accessToken,
        saldoChargeAmount,
        paymentIntentId
      );

      if (res.error) throw res.error;
      if (res.data) {
        this.setState((prevState) => ({
          hasResult: !prevState.hasResult,
          error: {
            hasError: false,
            errorId: "",
          },
        }));
        setTimeout(() => {
          let token = jwt;
          if (this.props.info.success_url === RECEIPT_SUCCESS_URL) {
            token = receiptToken;
          }
          window.location.replace(
            appendQueryString(this.props.info.success_url, `jwt=${token}`)
          );
        }, 1500);
      }
    } catch (e: any) {
      this.setState((prevState) => ({
        hasResult: !prevState.hasResult,
        error: {
          hasError: true,
          errorId: getPaymentErrorId(e),
        },
      }));
      setTimeout(() => {
        window.location.replace(this.props.info.cancel_url);
      }, 1500);
    }
  };

  handleTimeout = () => {
    this.setState((prevState) => ({
      hasResult: !prevState.hasResult,
      error: {
        hasError: true,
        errorId: "timeout",
      },
    }));
    setTimeout(() => {
      window.location.replace(this.props.info.cancel_url);
    }, 1500);
  };

  render() {
    const { info, venue, accounts, locale, isLoading } = this.props;
    return (
      <AppContent>
        <VenueSection venue={venue} info={info} />
        {info.iat ? (
          <Countdown
            iat={info.iat}
            handleTimeout={this.handleTimeout}
            render={({ clearCountDown }: { clearCountDown: () => any }) => (
              <AccountSection
                locale={locale}
                accounts={accounts}
                isLoading={isLoading}
                info={info}
                handleSubmit={this.handleSubmit}
                clearCountDown={clearCountDown}
              />
            )}
          />
        ) : (
          <AccountSection
            accounts={accounts}
            isLoading={isLoading}
            info={info}
            handleSubmit={this.handleSubmit}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            clearCountDown={() => {}}
            locale={locale}
          />
        )}
        {this.state.hasResult && (
          <Modal
            isOpen={true}
            error={this.state.error}
            successUrl={info.success_url}
            cancelUrl={info.cancel_url}
          />
        )}
      </AppContent>
    );
  }
}

const InjectedAppContentComponent: React.FC<InjectedAppContentProps> = (
  props
) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <AppContentComponent stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedAppContentComponent;
